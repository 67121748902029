.dropdown-select {
  border: 1px solid #95a1be;
  padding: 1px;
  height: 30px;
  width: fit-content;
  font-weight: 400;
  font-size: 12px;
  color: #95a1be;
  border-radius: 4px;
}

.icon {
  cursor: pointer;
}

.pointer-cursor {
  cursor: pointer;
}

.hover-dim:hover {
  opacity: 70%;
}

.hover-click {
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hover-click:hover {
  transform: scale(1.01);
}

.hover-click:active {
  transform: scale(0.99);
}

.bg-primary-light {
  background-color: #e0cbf6 !important;
}

.no-outline {
  outline: none;
  box-shadow: none; /* Optional: To remove any box shadow */
}

.custom-button {
  border-width: 3px;
}
