.header-container {
    display: flex;
    /* justify-content: flex-end; */
    padding: 0 5px 0 5px;
    z-index: 30;
    /* height: 7vh; */
    margin-top: 2rem;
    width: 100%;
}
.header-container label, .header-container .login-user{
    color: #2B3674;
    font-size: 0.875rem;
    font-weight: 500;
}
.header-container input{
border: 1px solid #A3AED0 !important;
color: #A3AED0;
width: 100%;
margin-left: 10px;
padding: 2px 10px !important;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
border-radius: 4px !important;
font-size: 0.75em;
height: auto !important;
margin-top: 0 !important;
}

.subheader {
    font-size: 14px;
    text-align: left;
    font-weight: 700;
    /* margin: ; */
    color: #707eae;
}

.subheader-title {
    font-size: 34px;
    text-align: left;
    font-weight: 800;
    color: #2b3674;
}

.subtext-1 {
    color: grey;
    font-size: 14px;
}

.data-text-1 {
    font-size: 22px;
    font-weight: 800;

}


.welcome-title {
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: black
}

.welcome-subtext {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #888b9c;
}

.welcome-input-label {
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #868686;
}

.welcome-input {

    border: 1px solid #d9d9d9;
    padding: 1px;
    border-radius: 4px;
    margin-top: 8px;
    height: 40px;
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    color: #2d2d2d;

}


.data-card {

    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    background: #ffffff;
    border-radius: 20px;
    /* margin: 0px 5px 5px 5px; */
    align-items: center;

}

.data-card-active {

    border: 0px solid #8a21fa;
    border-radius: 18px;
    padding: 20px 10px 10px 10px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(269deg, #8026df, #b47cef);
    box-shadow: 4px -6px 7px 0px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
    margin: 0px 5px 5px 5px;
}

.data-card:hover {

    transform: scale(1.02);

}

.data-card-active:hover {

    transform: scale(1.02);

}

.data-card-active-title {
    color: white !important;
    font-size: 14px !important;
    font-weight: 4 00;

}

.data-card-active-data {
    color: white;
    font-size: 30px !important;
    font-weight: 500 !important;
}

.data-card-title {
    color: #A3AED0 !important;
    font-size: 14px !important;
    font-weight: 500;
    text-align: center;
}
.data-card-admin{
    color: #A3AED0 !important;
    font-size: 16px !important;
    font-weight: 500;
}

.data-card-data {
    color: #1B2559;
    font-size: 30px !important;
    font-weight: 700 !important;
}
.data-card .svg-bg{
    width: 56px;
height: 56px;
background-color: #F0F0F0;
border-radius: 50%;
}
.data-card .svg-bg path{
    fill: #8A21FA !important;
}
.data-card .svg-bg svg{
     width: 28px;
    height: 28px;;
}

/*  */
.event-header {
    color: #1b2559;
    font-size: 34px;
    font-weight: 700;
    /* margin: ; */

}

.event-title {

    color: #1b2559;
    font-size: 16px;
    font-weight: 700;


}

.event-line {
    border-left: 3px solid #8a21fa;
    border-right: 3px solid #8a21fa;
    height: 50px;
    border-radius: 10px;
    margin-right: 10px;
}

.event-subtext {
    color: #A3AED0;
    font-size: 12px;
    font-weight: 500;
}

.branch-header {
    font-weight: 800 !important;
    color: #2b3674;
}

.teacher-header {
    font-weight: 700 !important;
    color: #2b3674;
    font-size: 24px;
}

.branch-select {
    border: 1px solid #95A1BE;
    /* padding: 1px; */
    height: 30px;
    width: 60px;
    font-weight: 200;
    font-size: 12px;
    color: #888b9c;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
}

.teacher-select {
    border: 1px solid #95A1BE;
    /* padding: 1px; */
    height: 30px;
    width: 60px;
    font-weight: 200;
    font-size: 12px;
    color: #888b9c;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
}
.blood-group-dropdown{
    border: 1px solid #d9d9d9;
    height: 40px;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: #2d2d2d;
    border-radius: 4px;
    cursor: pointer;
}
.title-dropdown{
    border: 1px solid #ced4da;
    /* padding: 1px; */
    height: 40px;
    width: 100%;
    font-weight: 200;
    font-size: 15px;
    color: #888b9c;
    border-radius: 4px;
    text-align: left;
    cursor: pointer;
    transition: ease-in-out;
}
.year-dropdown {
    border: 1px solid #ced4da;
    /* padding: 1px; */
    height: 40px;
    /* width: 80px; */
    font-weight: 200;
    font-size: 15px;
    color: #888b9c;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    transition: ease-in-out;
}

.circle-btn-add {
    display: flex;
    width: 50%;
    height: 50px;
    padding: 28px 47px 0px 0px;
    border-radius: 50%;
    border: none;
    gap: 4px;
}



.add-upload-button {
    background-color: white !important;
    /* border: 2px solid !important; */
    color: #8A21FA !important;
    border-radius: 50% !important;
    height: 65px !important;
    font-size: 14px !important;
    font-weight: 800 !important;
    width: 65px;
}

.delete-upload-button {
    background-color: white !important;
    /* border: 2px solid !important; */
    color: red !important;
    font-size: 13px !important;
    font-weight: 800 !important;
    border-radius: 50% !important;
    height: 65px !important;
    width: 70px;
}


.radio-button-text{
    font-weight: 500;
    font-size: 20px;
}
.gender-select{
    border: 1px solid #ced4da;
    font-weight: 200;
    height: 48px;
    width: 100%;
    font-size: 16px;
    color: black;
    border-radius: 4px;
    
    cursor: pointer;

}

.phone-number-select {


    border: 1px solid #ced4da;
    font-weight: 500;
    height: 48px;
    width: 85px;
    font-size: 16px;
    color: black;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;


    /* border: 1px solid #95A1BE;
    /* padding: 1px; 
    height: 30px;
    width: 60px;
    font-weight: 200;
    font-size: 12px;
    color: #888b9c;
    border-radius: 4px;
    text-align: center;
    cursor: pointer; */

}



.branch-search {

    border-radius: 10px !important;
    height: 44px !important;
    width: 200px !important;
    color: #8f9bba !important;
    font-size: 12px !important;
    font-weight: 400;

}

.teacher-search {

    border-radius: 10px !important;
    height: 44px !important;
    width: 200px !important;
    color: #8f9bba !important;
    font-size: 12px !important;
    font-weight: 400;

}

/* .add-new-branch {
    background-color: #8121fa !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: white !important;
} */

.add-new-teacher {
    background-color: #8121fa !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: white !important;
}

.add-new-bulk-teacher {
    border: 2px solid #8121fa !important;
    color: #8121fa !important;
    font-weight: 500 !important;
    background-color: transparent !important;


}

.add-new-branch-outline {
    background-color: white !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #8121fa !important;
    border: 1px solid #8a21fa !important;
}

.add-new-branch-outline:hover {
    background-color: #8121fa !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: white !important;
    border: 1px solid #8a21fa !important;
}

.save-button {
    background-color: #8121fa !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    color: white !important;
}


.popup-cancel-button {
    background-color: white !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    color: #8121fa !important;
    border: 2px solid #8a21fa !important;
}

.popup-cancel-button:hover {
    background-color: #8121fa !important;
    color: white !important;

}

.popup-cancel-button-outline {
    background-color: red !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    color: white !important;
    border: 2px solid red !important;
}

.admin-image{
    border-radius: 50%;
    height: 100%;
} 
.admin-image-div{
    display: flex;
    justify-content: center;
    align-items: center;
    width:120px;
    height:120px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.admin-text{
    height:80px;
    width:200px;
}
.delete-new-branch{
    color: red;
    background-color: transparent;
    border: 1px solid red;
    border-radius: 4px;
}
#branch-search{
    font-size: 	0.75rem;
}
#branch-search:focus-visible{
    outline: none !important;
    box-shadow: none;
    outline-offset: 0px !important;
}
.branch-header{
    font-size: 1.5rem;
}

/* */

.dashboard-pattern{
    background-color: #e5e5f7;
    background-image: linear-gradient(#dfcbfb 1px, transparent 1px), linear-gradient(to right, #dfcbfb 1px, #e5e5f7 1px);
    background-size: 20px 20px;
    padding: 25px;
    border-radius: 20px;
    margin: 16px 5px 5px;
}
.feature-card{
    background-color: #FFCFE7;
    border-left: 8px solid #FFABD5;
    padding: 14px 20px;
    margin-bottom: 16px;
}