.header {
    /* width: 100%; */
    display: flex;
    position: relative;
    justify-content: space-between;
    height: 12vh;
}

.text-small-1 {
    font-size: 14px;
    color: #2b3674;
    font-weight: 500;
}

.text-small-2 {
    font-size: 12px;
    color: #2b3674;
    font-weight: 400;
}

.roles-data {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-weight: 500 !important;
    width: auto;
    padding: 0px 5px 0px 5px;
    margin: 7px 3px 0px 3px ;
    height: 24px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.02em;

    
    flex-wrap: wrap;
    align-content: space-around;
}


.period-label{
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.36px;
}

.period-box{
    height: 100px;
    background-color: #8A21FA14;
    border: 2px solid #8A21FA;
    width : 200px;
    border-radius: 5px;
}

.period-title{
    color: #2B3674;
    text-align: start;

    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.28px;
}

.period-from{
    color: var(--neutral-grey-1, #95A1BE);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 300% */
    letter-spacing: -0.28px;
}

.period-time{
    color: #868686;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 300% */
    letter-spacing: -0.28px;
    background-color: transparent;
    outline: none;
    width: 100%; 
}

.period-break{
    color: var(--neutral-black, #2D2D2D);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
}