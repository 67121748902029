.createbtn{
    background-color: #8A21FA !important;
    height: 32px !important;
    padding: 0px 24px 0px 16px;
    color: #fff !important;
    padding: 10px 24px;
  }
.editbtn{
  background-color: white !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  color: #8121fa !important;
  border: 2px solid #8a21fa !important;
  width: 90px;
  height: 48px !important;
  padding: 10px 24px;
}
.deletebtn{
  font-weight: 700 !important;
    font-size: 15px !important;
    color: red !important;
    height: 48px !important;
    padding: 10px 24px;
}

.deletebtn:hover{
  text-decoration: underline;
}
.cancelbtn{
  background-color: white !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  color: #8121fa !important;
  border: 2px solid #8a21fa !important;
  height: 48px !important;
  width: 90px;
  padding: 10px 24px;
}

.savebtn{
  background-color: #8121fa !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    color: white !important;
    height: 48px !important;
  width: 90px;
  padding: 10px 24px;
}
.uploadbtn{
  background-color: #8A21FA !important;
  height: 48px !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 24px;
}