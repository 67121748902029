


.branch-data-table{
    font-size: 14px;
    border-bottom: 1px solid rgba(174, 184, 214, 0.2);
    font-weight: bold;
    color: black;
}

.branch-data-table-header{
    color:rgba(163, 174, 208, 1);
    font-weight:600;
    font-size: 16px;
}

.branch-data-input{

    padding: 1px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 10px;
    color:rgba(138, 33, 250, 1);

    background-color:rgba(138, 33, 250, 0.2);
    padding-top:3px;
    padding-bottom: 3px;
    padding-left: 14px;
    padding-right: 10px;
}

.add-branch-title{
    font-size: 24px;
    text-align: left;
    font-weight: 800;
    color: #2b3674;
}

.add-branch-subtitle{
    font-size: 16px;
    font-weight: 800;
    color: #2b3674;
}

.add-branch-label{
    font-size: 14px;
    font-weight: 600;
    color: #868686;
}

.add-branch-input{

    border: 1px solid #d9d9d9;
    padding-left: 5px;
    border-radius: 4px;
    margin-top: 8px;
    height: 40px;
    font-size: 18px;
    color: #2d2d2d;
}

.add-branch-header-tab-active{
    font-size: 15px !important;
    height: 50px !important;
    font-weight: bold !important;
    background-color: rgba(138, 33, 250, 1) !important;
    color: white !important;
}

.add-branch-header-tab{
    font-size: 14px;
    font-weight: bold;
    background-color: none;
    color: rgba(138, 33, 250, 1) !important;
}