.add-teacher-title {
    font-size: 34px;
    text-align: left;
    font-weight: 800;
    color: #2b3674;
    margin-bottom: 0;
}

.add-teacher-subtitle {
    font-size: 16px;
    font-weight: 800;
    color: #2b3674;
}

.teacher-data-table {
    font-size: 14px;
    border-bottom: 1px solid rgba(174, 184, 214, 0.2);
    font-weight: bold;
    color: black;
}

.teacher-data-table-header {
    color: rgba(163, 174, 208, 1);
    font-weight: 600;
    font-size: 16px;
}

.teacher-data-input {

    padding: 1px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 10px;
    color: rgba(138, 33, 250, 1);

    background-color: rgba(138, 33, 250, 0.2);
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 14px;
    padding-right: 10px;
}

.teacher-attendance {
    color: rgb(231, 73, 73);
}

.add-branch-label {
    font-size: 0.75rem;
    font-weight: 700;
    color: #868686;
}

.add-branch-input {

    border: 1px solid #d9d9d9;
    padding-left: 5px;
    border-radius: 4px;
    margin-top: 8px;
    height: 48px !important;
    font-size: 18px;
    color: #2d2d2d;
}

.add-branch-header-tab-active {
    font-size: 14px !important;
    height: 56px !important;
    font-weight: 700 !important;
    background-color: rgba(138, 33, 250, 1) !important;
    color: #fff !important;
}
.add-branch-header-tab{
    border-radius: 5px 5px 0px 0px;
background: #F5F5F5;
font-size: 14px !important;
    height: 56px !important;
}
.add-branch-header-tab {
    font-size: 14px;
    font-weight: bold;
    background-color: none;
    color: none;
}

.custom-select {
    border: 1px solid #95A1BE;
    padding: 1px;
    height: 30px;
    width: auto;
    font-Weight: 400;
    font-Size: 10px;
    color: #2B3674;
    border-radius: 4px;
}

.teacher-campus-select {
    font-weight: 200px;
    font-Size: 14px;
    color: #2B3674;
    padding-top: 5px;
}