.button-outline-1 {
    background-color: white !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    color: #8121fa !important;
    border: 2px solid #8a21fa !important;
}


.button-1 {
    background-color: #8121fa !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    color: white !important;
}

.left-arrow-button{
    font-weight: 700 !important;
    font-size: 15px !important;
    color: #8a21fa !important;
}

/* .text-delete-button{
    font-weight: 700 !important;
    font-size: 15px !important;
    color: red !important;
} */