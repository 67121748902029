.profile-container{
    border: 1px solid #A3AED0;
    border-radius: 10px;
    
}
.Profile-image-container{
    height: 160px;
    width: 160px;
    object-fit: cover;
   background-color: #D9D9D9;
}
.Admin-credentials input{
    border: 1px solid #D9D9D9;
    height: 48px;
    padding: 0px 8px;
    margin-bottom: 20px;
    border-radius: 5px;
    font-size: 1.125rem;
}
.Admin-credentials label{
    color: #868686;
    font-size: 0.75rem;
    font-weight: 700;
    margin-bottom: 6px;
}