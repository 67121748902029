.login-header {
    font-weight: 700;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -0.02em;
    color: #2B3674;
}

.input-header {
    font-size: 12px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #868686;

}

.phone-select {
    border: 1px solid #ced4da;
    font-weight: 500;
    height: 40px;
    width: 85px;
    font-size: 16px;
    color: black;
    border-radius: 4px 0px 0px 4px;
    text-align: center;
    cursor: pointer;
}

.input-phone-number {
    border: 1px solid #d9d9d9;
    padding-left: 5px;
    border-radius: 0px 4px 4px 0px;
    margin-top: 8px;
    height: 40px;
    font-size: 18px;
    color: #2d2d2d;
}

.login-submit {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    letter-spacing: 0.01em !important;
    text-align: center;
    background-color: #8121fa !important;
    color: white !important;
}

.otp-input {
    color: #8A21FA;
    width: 55px !important;
    height: 45px !important;
    margin: 0px 5px 0px 5px;
    border: 1px solid #8A21FA;
    border-radius: 5px;
    background: #FFFFFF;
}


.resend-otp {
    font-style: italic;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #2B3674;
    direction: rtl;
}


.error-text {
    color: var(--status-red-red-100, #FF4B4B);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}