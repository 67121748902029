.sidebar-container{
    display: flex;
    background: white;
    width: 100%;
    /* overflow-x: hidden; */
}

.sidebar{
    min-height: 100vh;
    max-height: 100vh;
    padding: 1rem 10px 1rem 10px;
    /* position:relative */
    /* overflow-y: scroll; */
}

.brand{
    object-fit: cover;
}

.sidebar-item{
    width: 100% !important  ;  
    background-color: white !important;
    color: #9d9d9d !important;
    display: flex !important ;
    justify-content: flex-start !important;
    align-items: center;
    text-align: left !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 10px 7px 10px 0;
    width: 18vw;
    border-radius: 6px;
    margin-top: 12px;
    margin: 0px;
    height: 48px;
}
.sidebar-item svg, .sidebar-item-active svg{
    height: 24px;
    width: 24px;
}
.sidebar-item svg path{
    fill: #868686;
}
.sidebar-item-active svg path{
    fill: #fff;
}

.sidebar-item:hover{ 
    background-color: #8a21fa !important;
    color: white !important;
}
.sidebar-item:hover svg path{
    fill: #fff;
}


.sidebar-item-active{
    width: 100% !important  ;  
    background-color: #8a21fa !important;
    color: white !important;
    display: flex !important ;
    justify-content: flex-start !important;
    align-items: center;
    text-align: left !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 10px 7px 10px 0;
    width: 18vw;
    border-radius: 6px;
    margin-top: 12px;
    margin: 0px;
    height: 48px;
}
.sub-title{
    border-radius: 5px;
background: rgba(138, 33, 250, 0.20);
display: flex;
align-items: center;
height: 32px;
font-style: normal;
font-weight: 700;
padding: 2px 10px 2px 48px;
}
.sub-title .sidebar-list-item-active{
    padding-top: 0 !important;
    font-size: 14px;

}

.sidebar-item-active:hover{ 

    background-color: #8a21fa;
    color: white;
}


.sidebar-arrow-icon{
    font-size: 20px;
}

.sidebar-list-title{
    font-size: 14px;
    font-weight: 500;
}

.sidebar-list-menu{
    list-style-type: none;
    font-size: 14px;
    font-weight: 500;
    color: #9d9d9d;
    cursor: pointer;
    padding: 0px 0px 5px 10px;
    margin-bottom: 0px;
    /* margin-top: -8px; */
}

.sidebar-list-item,.sidebar-list-item-active{
    padding: 10px 0px 0px 0px;
    font-weight: 800 !important;

}

.sidebar-list-item:hover{
    color: #8a21fa;
}

.sidebar-list-item-active{
    color: #8a21fa;
    font-weight: 800 !important;
}
.sidebar-list-item-active:hover{
    color: #ae6af6;
}

.sidebar-tab{
    width: 150px;
    font-size: 1rem;
}