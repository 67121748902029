input {
height: 48px;
padding: 0px 8px;
border-radius: 5px;
border: 1px solid #D9D9D9;
background-color: #fff !important;
margin-top: 8px;
color: #868686 !important;

/* border: 1px solid #d9d9d9;
padding-left: 5px;
border-radius: 4px;
margin-top: 8px;
height: 40px;
font-size: 18px;
color: #2d2d2d; */
}

input[type= "checkbox"]{
    height: auto !important;
    margin-top: 0 !important;
}